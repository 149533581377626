<template>
  <div class="box">
    <div class="line1">
      <span class="adjust-update-img">调剂更新</span>
      <span class="adjust-update-txt">调剂数据最后更新于 {{ convertDateTime(lastUpdateTime) }}</span>
    </div>
    <div class="line2">
      <div class="item-box">
        <div class="title">今日更新院校</div>
        <div class="count">{{ schoolCountOfToday == 0 ? '待更新' : schoolCountOfToday }}</div>
      </div>
      <div class="item-box">
        <div class="title">今日数据更新</div>
        <div class="count">{{ totalCountOfToday == 0 ? '待更新' : totalCountOfToday }}</div>
      </div>
      <div class="item-box">
        <div class="title">24考研累计院校</div>
        <div class="count">{{ schoolCountOfYear == 0 ? '待更新' : schoolCountOfYear }}</div>
      </div>
      <div class="item-box">
        <div class="title">24考研累计数据</div>
        <div class="count">{{ totalCountOfYear == 0 ? '待更新' : totalCountOfYear }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/tool";
export default {
  name: "AdjustStatistic",
  components: {

  },
  props: {
    lastUpdateTime: {
      type: String,
      default: '2024-01-01T10:00:09',
    },
    totalCountOfToday: {
      type: Number,
      default: 0,
    },
    schoolCountOfToday: {
      type: Number,
      default: 0,
    },
    schoolCountOfYear: {
      type: Number,
      default: 0,
    },
    totalCountOfYear: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    //转换日期
    convertDateTime(value) {
      return Tools.formatDate(new Date(value), 'yyyy-MM-dd HH:mm')
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #FEF5D6;
}

.line1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .adjust-update-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    padding-left: 20px;
    font-size: 18px;
    width: 120px;
    height: 32px;
    background-image: url(~@/assets//images/comm/adjust-update.png);
    background-size: 100% 100%;
  }

  .adjust-update-txt {
    color: #E37841;
    font-size: 14px;
    margin-right: 10px;
  }
}

.line2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-box {
    width: 180px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(~@/assets//images/comm/adjust-item.png);
    background-size: 100% 100%;
    margin: 20px;

    .title {
      color: white;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .count {
      height: 28px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      color: #FE5E03;
      font-size: 14px;
      font-weight: bold;
      margin-top: 5px;
    }
  }
}
</style>
