<template>
  <div class="filter-box">
    <van-cell-group :border="false" title="调剂年份">
      <check-border-group v-model="value1" class="check-border-group" @change="onChange1">
        <check-border class="check-border" label="2024年" name="2024" icon></check-border>
        <check-border class="check-border" label="2023年" name="2023" icon></check-border>
      </check-border-group>
    </van-cell-group>
    <van-cell-group :border="false" title="学位类型">
      <check-border-group v-model="value2" class="check-border-group" @change="onChange2">
        <check-border class="check-border" label="专业型硕士" name="专业型硕士" icon></check-border>
        <check-border class="check-border" label="学术型硕士" name="学术型硕士" icon></check-border>
      </check-border-group>
    </van-cell-group>
    <van-cell-group title="选择专业">
      <radio-cell-group clear v-model="value3" title="所属门类" placeholder="请选择所属门类" @change="onChange3">
        <radio-cell v-for="(item, index) in majorList1" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
      <check-cell-group v-if="value3" clear v-model="value4" title="学科类别" placeholder="请选择学科类别" @change="onChange4">
        <check-cell v-for="(item, index) in majorList2" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
      <check-cell-group v-if="value4.length > 0" clear v-model="value5" title="备考专业" placeholder="请选择备考专业" @change="onChange5">
        <check-cell v-for="(item, index) in majorList3" :key="index" :name="item" :label="item"></check-cell>
      </check-cell-group>
    </van-cell-group>
    <van-cell-group title="选择学校">
      <check-cell-group clear v-model="value6" title="学校位置" placeholder="请选择学校位置" @change="onChange6" @show-popup="onPopup6">
        <!-- <div class="title-area">A区</div>
        <check-cell label="全部" @click="value6 = []"></check-cell> -->
        <check-cell v-for="(item, index) in schoolProvinces" :key="index" :name="item" :label="item"></check-cell>
        <!-- <div class="title-area">B区</div>
        <check-cell label="全部" @click="value6 = []"></check-cell> -->
      </check-cell-group>
      <radio-cell-group clear v-model="value7" title="院校特色" placeholder="请选择院校特色" @change="onChange7" @show-popup="onPopup7">
        <radio-cell v-for="(item, index) in schoolCharacteristics" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
  </div>
</template>

<script>
import RadioCell from "@/components/MT/RadioCell";
import RadioCellGroup from "@/components/MT/RadioCellGroup";
import CheckCell from "@/components/MT/CheckCell";
import CheckCellGroup from "@/components/MT/CheckCellGroup";
import CheckBorder from "@/components/MT/CheckBorder";
import CheckBorderGroup from "@/components/MT/CheckBorderGroup";
import Config from "@/api/service";
export default {
  name: "AdjustFilter",
  components: {
    RadioCell,
    RadioCellGroup,
    CheckCell,
    CheckCellGroup,
    CheckBorder,
    CheckBorderGroup,
  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: Array,
      default: [],
    },
    filter5: {
      type: Array,
      default: [],
    },
    filter6: {
      type: Array,
      default: [],
    },
    filter7: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      schoolProvinces: ['北京', '天津', '河北', '山西', '辽宁', '吉林', '黑龙江', '上海', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '重庆', '四川', '陕西', '内蒙古', '广西', '海南', '贵州', '云南', '西藏', '甘肃', '青海', '宁夏', '新疆'],
      schoolCharacteristics: ['985', '211', '双一流', '自划线', '高等院校', '科研院所'],
      majorList1: [],
      majorList2: [],
      majorList3: [],
    };
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
    value5: {
      get: function () {
        return this.filter5;
      },
      set: function (val) {
        this.$emit('update:filter5', val);
      }
    },
    value6: {
      get: function () {
        return this.filter6;
      },
      set: function (val) {
        this.$emit('update:filter6', val);
      }
    },
    value7: {
      get: function () {
        return this.filter7;
      },
      set: function (val) {
        this.$emit('update:filter7', val);
      }
    },
  },
  methods: {
    getList1() {
      setTimeout(() => {
        this.majorList1 = [];
        this.majorList2 = [];
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
        };
        this.$axios
          .post(Config.adjust.filter.belongCategoryList, model)
          .then((res) => {
            this.majorList1 = res.data.Data.MajorBelongcategorys;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getList2() {
      setTimeout(() => {
        this.majorList2 = [];
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
        };
        this.$axios
          .post(Config.adjust.filter.firstDisciplineList, model)
          .then((res) => {
            this.majorList2 = res.data.Data.MajorBelongFirstDisciplines;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getList3() {
      setTimeout(() => {
        this.majorList3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
        };
        this.$axios
          .post(Config.adjust.filter.majorCodeNameList, model)
          .then((res) => {
            this.majorList3 = res.data.Data.MajorCodeNames;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    onChange1() {
      this.value2 = [];
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit("filter");
    },
    onChange2() {
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.getList1();
      this.$emit("filter");
    },
    onChange3() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.getList2();
      this.$emit("filter");
    },
    onChange4() {
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.getList3();
      this.$emit("filter");
    },
    onChange5() {
      this.value6 = [];
      this.value7 = '';
      this.$emit("filter");
    },
    onChange6() {
      this.value7 = '';
      this.$emit("filter");
    },
    onChange7(cear) {
      this.$emit('filter');
      this.popup5 = false;
    },
    onPopup6(e) {
      if (!this.unlocked) {
        e.prevent = true;
        this.$toast.fail("请付费解锁使用筛选功能。");
      }
    },
    onPopup7(e) {
      if (!this.unlocked) {
        e.prevent = true;
        this.$toast.fail("请付费解锁使用筛选功能。");
      }
    },
  },
  mounted() {
    this.getList1();
  }
};
</script>

<style scoped lang="less">
.filter-box {
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.check-border-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-border {
  width: 48%;
}

.popup {
  height: 80%;
}

.popup-content {
  margin: 0px 10px 10px 10px;
}

.title-area {
  margin: 20px 0 5px 5px;
  font-size: 14px;
  color: gray;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}
</style>
