<template>
  <div>
    <div class="box flex">
      <div class="text1">年 份</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value1">
        <radio-item label="全部" :checked="value1.length == 0" @click="onClear1"></radio-item>
        <check-item label="2024" name="2024" @click="onClick1"></check-item>
        <check-item label="2023" name="2023" @click="onClick1"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">学 位</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value2">
        <radio-item label="全部" :checked="value2.length == 0" @click="onClear2"></radio-item>
        <check-item label="专业型硕士" name="专业型硕士" @click="onClick2"></check-item>
        <check-item label="学术型硕士" name="学术型硕士" @click="onClick2"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">门 类</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value3">
        <div class="fill">
          <radio-item label="全部" name="" @click="onClear3"></radio-item>
          <radio-item v-for="(item, index) in list1" :key="index" :label="item" :name="item" @click="onClick3"></radio-item>
        </div>
      </radio-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex" v-if="list2.length > 0">
      <div class="text1">学科类别</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value4">
        <radio-item label="全部" :checked="value4.length == 0" @click="onClear4"></radio-item>
        <div class="fill">
          <check-item v-for="(item, index) in list2" :key="index" :label="item" :name="item" @click="onClick4"></check-item>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex" v-if="list3.length > 0">
      <div class="text1">备考专业</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value5">
        <radio-item label="全部" :checked="value5.length == 0" @click="onClear5"></radio-item>
        <div class="fill">
          <check-item v-for="(item, index) in list3" :key="index" :label="item" :name="item" @click="onClick5"></check-item>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">学院位置</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value6">
        <radio-item label="全部" :checked="value6.length == 0" @click="onClear6"></radio-item>
        <div class="fill">
          <div class="flex">
            <div class="text2">A区</div>
            <div class="fill">
              <check-item label="北京" name="北京" @click="onClick6"></check-item>
              <check-item label="天津" name="天津" @click="onClick6"></check-item>
              <check-item label="河北" name="河北" @click="onClick6"></check-item>
              <check-item label="山西" name="山西" @click="onClick6"></check-item>
              <check-item label="辽宁" name="辽宁" @click="onClick6"></check-item>
              <check-item label="吉林" name="吉林" @click="onClick6"></check-item>
              <check-item label="黑龙江" name="黑龙江" @click="onClick6"></check-item>
              <check-item label="上海" name="上海" @click="onClick6"></check-item>
              <check-item label="江苏" name="江苏" @click="onClick6"></check-item>
              <check-item label="浙江" name="浙江" @click="onClick6"></check-item>
              <check-item label="安徽" name="安徽" @click="onClick6"></check-item>
              <check-item label="福建" name="福建" @click="onClick6"></check-item>
              <check-item label="江西" name="江西" @click="onClick6"></check-item>
              <check-item label="山东" name="山东" @click="onClick6"></check-item>
              <check-item label="河南" name="河南" @click="onClick6"></check-item>
              <check-item label="湖北" name="湖北" @click="onClick6"></check-item>
              <check-item label="湖南" name="湖南" @click="onClick6"></check-item>
              <check-item label="广东" name="广东" @click="onClick6"></check-item>
              <check-item label="重庆" name="重庆" @click="onClick6"></check-item>
              <check-item label="四川" name="四川" @click="onClick6"></check-item>
              <check-item label="陕西" name="陕西" @click="onClick6"></check-item>
            </div>
          </div>
          <div class="flex">
            <div class="text2">B区</div>
            <div class="fill">
              <check-item label="内蒙古" name="内蒙古" @click="onClick6"></check-item>
              <check-item label="广西" name="广西" @click="onClick6"></check-item>
              <check-item label="海南" name="海南" @click="onClick6"></check-item>
              <check-item label="贵州" name="贵州" @click="onClick6"></check-item>
              <check-item label="云南" name="云南" @click="onClick6"></check-item>
              <check-item label="西藏" name="西藏" @click="onClick6"></check-item>
              <check-item label="甘肃" name="甘肃" @click="onClick6"></check-item>
              <check-item label="青海" name="青海" @click="onClick6"></check-item>
              <check-item label="宁夏" name="宁夏" @click="onClick6"></check-item>
              <check-item label="新疆" name="新疆" @click="onClick6"></check-item>
            </div>
          </div>
        </div>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">院校特色</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value7">
        <radio-item label="全部" name="" @click="onClear7"></radio-item>
        <div class="fill">
          <radio-item label="985" name="985" @click="onClick7"></radio-item>
          <radio-item label="211" name="211" @click="onClick7"></radio-item>
          <radio-item label="双一流" name="双一流" @click="onClick7"></radio-item>
          <radio-item label="自划线" name="自划线" @click="onClick7"></radio-item>
          <radio-item label="高等院校" name="高等院校" @click="onClick7"></radio-item>
          <radio-item label="科研院所" name="科研院所" @click="onClick7"></radio-item>
        </div>
      </radio-item-group>
    </div>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import CheckItem from "@/components/PC/CheckItem";
import CheckItemGroup from "@/components/PC/CheckItemGroup";
import Config from "@/api/service";
export default {
  name: "AdjustFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: Array,
      default: [],
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: Array,
      default: [],
    },
    filter5: {
      type: Array,
      default: [],
    },
    filter6: {
      type: Array,
      default: [],
    },
    filter7: {
      type: String,
      default: '',
    },
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
    value5: {
      get: function () {
        return this.filter5;
      },
      set: function (val) {
        this.$emit('update:filter5', val);
      }
    },
    value6: {
      get: function () {
        return this.filter6;
      },
      set: function (val) {
        this.$emit('update:filter6', val);
      }
    },
    value7: {
      get: function () {
        return this.filter7;
      },
      set: function (val) {
        this.$emit('update:filter7', val);
      }
    },
  },
  data() {
    return {
      list1: [],
      list2: [],
      list3: [],
    };
  },
  methods: {
    getList1() {
      setTimeout(() => {
        this.list1 = [];
        this.list2 = [];
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
        };
        this.$axios
          .post(Config.adjust.filter.belongCategoryList, model)
          .then((res) => {
            this.list1 = res.data.Data.MajorBelongcategorys;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getList2() {
      setTimeout(() => {
        this.list2 = [];
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
        };
        this.$axios
          .post(Config.adjust.filter.firstDisciplineList, model)
          .then((res) => {
            this.list2 = res.data.Data.MajorBelongFirstDisciplines;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getList3() {
      setTimeout(() => {
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value2,
          MajorBelongcategory: this.value3,
          MajorBelongFirstDisciplines: this.value4,
        };
        this.$axios
          .post(Config.adjust.filter.majorCodeNameList, model)
          .then((res) => {
            this.list3 = res.data.Data.MajorCodeNames;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    onClear1() {
      this.value1 = [];
      this.onClick1();
    },
    onClear2() {
      this.value2 = [];
      this.onClick2();
    },
    onClear3() {
      this.value3 = '';
      this.onClick3();
    },
    onClear4() {
      this.value4 = [];
      this.onClick4();
    },
    onClear5() {
      this.value5 = [];
      this.onClick5();
    },
    onClear6() {
      this.value6 = [];
      this.onClick6();
    },
    onClear7() {
      this.value7 = '';
      this.onClick7();
    },
    onClick1() {
      this.value2 = [];
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick2() {
      this.value3 = '';
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.getList1();
      this.$emit('filter');
    },
    onClick3() {
      this.value4 = [];
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.getList2();
      this.$emit('filter');
    },
    onClick4() {
      this.value5 = [];
      this.value6 = [];
      this.value7 = '';
      this.getList3();
      this.$emit('filter');
    },
    onClick5() {
      this.value6 = [];
      this.value7 = '';
      this.$emit('filter');
    },
    onClick6() {
      if (!this.unlocked) {
        this.value6 = [];
        this.$alert('请付费解锁使用筛选功能。', '提示');
      } else {
        this.value7 = '';
        this.$emit('filter');
      }
    },
    onClick7() {
      if (!this.unlocked) {
        this.value7 = '';
        this.$alert('请付费解锁使用筛选功能。', '提示');
      } else {
        this.$emit('filter');
      }
    },
  },
  mounted() {
    this.getList1();
  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 14px;
}

.img {
  width: 15px;
  height: 15px;
  padding: 10px 0;
  margin-top: 2px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.fill {
  flex: 1;
}

.text1 {
  padding: 10px;
  color: black;
  word-spacing: 24px;
}

.text2 {
  width: 30px;
  padding: 10px;
  color: gray;
}

.margin-column {
  margin: 20px 0;
}
</style>
