<template>
  <div class="box" @click="onClick">
    <div class="line1">
      <div v-if="unlocked" class="col1">
        <span class="title">{{ universityName }}-{{ majorName }}</span>
      </div>
      <div v-else class="col1">
        <span class="title">
          <img :src="require('@/assets/images/comm/lock.png')" class="lock-img" />
          <span> - </span>
          <span>{{ majorName }}</span>
        </span>
      </div>
      <div v-if="unlocked" class="col2">
        <img src="@/assets/images/pc/position.png" class="img-position" />
        <span class="province">{{ universityProvince }}-{{ universityPartition }}区</span>
      </div>
      <div v-else class="col2">
        <img :src="require('@/assets/images/comm/lock.png')" class="lock-img" />
      </div>
      <div class="col3">
        {{ universityCharacteristic.replaceAll(' ', ' 丨 ') }}
      </div>
      <div class="col4">
        <el-button type="primary" size="mini" @click.stop="onClick">{{ unlocked ? '查看详情' : '解锁' }}</el-button>
      </div>
    </div>
    <div class="line2">
      <span class="text">招生年份：</span>
      <span class="text">{{ adjustYear }}</span>
      <span class="verticalbar"></span>
      <span class="text">专业代码：</span>
      <span class="text">{{ majorCode }}</span>
      <span class="verticalbar"></span>
      <span class="text">学习方式：</span>
      <span v-if="unlocked" class="text">{{ majorLearnway }}</span>
      <img v-else :src="require('@/assets/images/comm/lock.png')" class="lock-img" />
      <span class="verticalbar"></span>
      <span class="text">调剂名额：</span>
      <span v-if="unlocked" class="text">{{ adjustPeopleNumber }}</span>
      <img v-else :src="require('@/assets/images/comm/lock.png')" class="lock-img" />
      <span class="verticalbar"></span>
      <span class="text">所属学院：</span>
      <span v-if="unlocked" class="text">{{ collegeName }}</span>
      <img v-else :src="require('@/assets/images/comm/lock.png')" class="lock-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdjustItem",
  components: {

  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '@/assets/images/pc/school.png',
    },
    universityName: {
      type: String,
      default: '北京大学',
    },
    universityProvince: {
      type: String,
      default: '北京',
    },
    universityPartition: {
      type: String,
      default: 'A',
    },
    universityCharacteristic: {
      type: String,
      default: '985 211',
    },
    collegeCode: {
      type: String,
      default: '001',
    },
    collegeName: {
      type: String,
      default: '科学技术学院',
    },
    majorName: {
      type: String,
      default: '科学技术',
    },
    majorCode: {
      type: String,
      default: '100100',
    },
    majorLearnway: {
      type: String,
      default: '全日制',
    },
    adjustYear: {
      type: String,
      default: '2023',
    },
    adjustPeopleNumber: {
      type: String,
      default: '--',
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.box:hover {
  color: #FF6602;
}

.img-school {
  width: 60px;
  height: 60px;
}

.img-position {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.line1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  .col1 {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .col2 {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .col3 {
    flex: 1;
    word-spacing: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    color: #9f9f9f;
    margin-right: 20px;
  }

  .col4 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.line2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 700;
  white-space: pre-wrap;
}

.province {
  font-size: 14px;
  color: #888;
}

.text {
  font-size: 14px;
  color: #555;
  white-space: nowrap;
}

.text-ellipsis {
  font-size: 14px;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.verticalbar {
  border-left: 1px solid #c5c5c5;
  content: "";
  display: inline-block;
  width: 1px;
  height: 13px;
  margin: 0 10px;
  top: 2px;
}

.lock-img {
  width: 12px;
  height: 14px;
}
</style>
