<template>
    <div class="container-box" v-loading="loading" element-loading-text="加载中...">
        <div class="box-left">
            <div>
                <adjust-statistic :lastUpdateTime="adjustStatistic.LastUpdateTime" :schoolCountOfToday="adjustStatistic.SchoolCountOfToday" :totalCountOfToday="adjustStatistic.TotalCountOfToday" :schoolCountOfYear="adjustStatistic.SchoolCountOfYear" :totalCountOfYear="adjustStatistic.TotalCountOfYear"></adjust-statistic>
            </div>
            <div class="dark-border school-filter">
                <search-control class="search-control" placeholder="请输入院校或专业代码、名称" :value.sync="keyword" @search-click="onSearchClick"></search-control>
                <adjust-filter :unlocked="isMembership" :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" :filter5.sync="filter5" :filter6.sync="filter6" :filter7.sync="filter7" @filter="onFilterClick"></adjust-filter>
            </div>
            <div class="dark-border school-list">
                <div v-for="(adjust, index) in adjustInfos" :key="index">
                    <adjust-item :unlocked="adjust.AdjustGuid ? true : false" :universityName="adjust.AdjustUniversityName" :universityProvince="adjust.AdjustUniversityProvince" :universityPartition="adjust.AdjustUniversityPartition" :universityCharacteristic="adjust.AdjustUniversityCharacteristic" :collegeName="adjust.AdjustCollegeName" :majorName="adjust.AdjustMajorName" :majorCode="adjust.AdjustMajorCode" :majorLearnway="adjust.AdjustMajorLearningWay" :adjustYear="adjust.AdjustYear" :adjustPeopleNumber="adjust.AdjustPeopleNumber" @click="onItemClick(adjust)"></adjust-item>
                    <div v-if="index < adjustInfos.length - 1" class="bottom-line"></div>
                </div>
            </div>
            <div class="pagination-box">
                <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
            </div>
        </div>
        <div class="box-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import AdjustFilter from "@/components/PC/AdjustFilter";
import AdjustItem from "@/components/PC/AdjustItem";
import AdjustStatistic from "@/components/PC/AdjustStatistic";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        AdjustFilter,
        AdjustItem,
        AdjustStatistic,
        SchoolHot,
        SearchControl,
    },
    data() {
        return {
            loading: true,
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            keyword: '',
            filter1: [],
            filter2: [],
            filter3: '',
            filter4: [],
            filter5: [],
            filter6: [],
            filter7: '',
            isMembership: false,
            adjustInfos: [],
            adjustStatistic: {},
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                return `${Config.school.logo}?SchoolCode=${code}`;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取用户是否是会员
        getIsMembership() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {};
            this.$axios
                .post(Config.member.purchased, model)
                .then((res) => {
                    this.isMembership = res.data.Data.IsPurchased;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取调剂统计
        getAdjustStatistic() {
            var model = {};
            this.$axios
                .post(Config.adjust.statistic, model)
                .then((res) => {
                    this.adjustStatistic = res.data.Data;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取调剂列表
        getAdjustInfos() {
            this.loading = true;
            var model = {
                SearchKeyword: this.keyword,
                AdjustYears: this.filter1,
                AdjustMajorDegreeTypes: this.filter2,
                AdjustMajorBelongcategory: this.filter3,
                AdjustMajorBelongFirstDisciplines: this.filter4,
                AdjustMajorCodes: this.filter5.map(p => p.substring(0, 6)),
                AdjustUniversityProvinces: this.filter6,
                AdjustUniversityCharacteristic: this.filter7,
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
            };
            this.$axios
                .post(Config.adjust.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.totalCount = data.TotalCount;
                    this.adjustInfos = data.AdjustInfos;
                    this.loading = false;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击详情
        onItemClick(adjust) {
            if (adjust.AdjustGuid) {
                this.$router.push({
                    name: "Adjust1",
                    params: {
                        proxyCode: this.$store.state.proxyCode,
                        year: adjust.AdjustYear,
                        universityCode: adjust.AdjustUniversityCode,
                        collegeCode: adjust.AdjustCollegeCode,
                        majorCode: adjust.AdjustMajorCode,
                        majorLearnway: adjust.AdjustMajorLearningWay,
                    }
                });
            } else {
                this.$router.push({
                    name: "SubmitMemberOrder",
                    params: {
                        proxyCode: this.$store.state.proxyCode
                    }
                });
            }
        },
        //点击搜索
        onSearchClick() {
            this.pageIndex = 1;
            this.filter1 = [];
            this.filter2 = [];
            this.filter3 = '';
            this.filter4 = [];
            this.filter5 = [];
            this.getAdjustInfos();
        },
        //点击过滤
        onFilterClick() {
            this.pageIndex = 1;
            this.keyword = '';
            this.getAdjustInfos();
        },
        //点击分页
        onCurrentChange() {
            this.getAdjustInfos();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getIsMembership();
        this.getAdjustStatistic();
        this.getAdjustInfos();
    }
};
</script>

<style scoped>
.container-box {
    display: flex;
    flex-direction: row;
}

.box-left {
    flex: 1;
    overflow: hidden;
}

.box-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.search-control {
    width: 400px;
    margin: 10px 0;
}

.school-filter {
    margin-top: 20px;
    padding: 20px;
}

.school-list {
    margin-top: 20px;
    padding: 20px;
}

.dark-border {
    border: 1px solid #e6e6e6;
}

.bottom-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}
</style>