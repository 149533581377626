<template>
    <div>
        <van-sticky>
            <title-bar2 title="调剂公告" />
            <title-search :value.sync="keyword" placeholder="请输入院校或专业代码、名称" @search="onSearch" />
        </van-sticky>
        <adjust-statistic class="adjust-statistic" :lastUpdateTime="adjustStatistic.LastUpdateTime" :schoolCountOfToday="adjustStatistic.SchoolCountOfToday" :totalCountOfToday="adjustStatistic.TotalCountOfToday" :schoolCountOfYear="adjustStatistic.SchoolCountOfYear" :totalCountOfYear="adjustStatistic.TotalCountOfYear"></adjust-statistic>
        <adjust-filter :unlocked="isMembership" :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" :filter5.sync="filter5" :filter6.sync="filter6" :filter7.sync="filter7" @filter="onFilter"></adjust-filter>
        <div class="major-total">专业（{{ total }}个）</div>
        <van-list class="adjust-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <adjust-item class="adjust-item" v-for="(adjust, index) in adjustInfos" :key="index" :unlocked="adjust.AdjustGuid ? true : false" :universityName="adjust.AdjustUniversityName" :universityProvince="adjust.AdjustUniversityProvince" :universityPartition="adjust.AdjustUniversityPartition" :universityCharacteristic="adjust.AdjustUniversityCharacteristic" :collegeName="adjust.AdjustCollegeName" :majorName="adjust.AdjustMajorName" :majorCode="adjust.AdjustMajorCode" :majorLearnway="adjust.AdjustMajorLearningWay" :adjustYear="adjust.AdjustYear" :adjustPeopleNumber="adjust.AdjustPeopleNumber" :img="schoolImg(adjust.AdjustUniversityCode)" @click="onItemClick(adjust)"></adjust-item>
        </van-list>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import AdjustFilter from "@/components/MT/AdjustFilter";
import AdjustItem from "@/components/MT/AdjustItem";
import AdjustStatistic from "@/components/MT/AdjustStatistic";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
        AdjustFilter,
        AdjustItem,
        AdjustStatistic,
    },
    data() {
        return {
            keyword: null,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            filter1: [],
            filter2: [],
            filter3: '',
            filter4: [],
            filter5: [],
            filter6: [],
            filter7: '',
            isMembership: false,
            adjustInfos: [],
            adjustStatistic: {},
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                if (code) {
                    return `${Config.school.logo}?SchoolCode=${code}`;
                }
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取用户是否是会员
        getIsMembership() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {};
            this.$axios
                .post(Config.member.purchased, model)
                .then((res) => {
                    this.isMembership = res.data.Data.IsPurchased;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取调剂统计
        getAdjustStatistic() {
            var model = {};
            this.$axios
                .post(Config.adjust.statistic, model)
                .then((res) => {
                    this.adjustStatistic = res.data.Data;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取调剂列表
        getAdjustInfos() {
            var model = {
                SearchKeyword: this.keyword,
                AdjustYears: this.filter1,
                AdjustMajorDegreeTypes: this.filter2,
                AdjustMajorBelongcategory: this.filter3,
                AdjustMajorBelongFirstDisciplines: this.filter4,
                AdjustMajorCodes: this.filter5.map(p => p.substring(0, 6)),
                AdjustUniversityProvinces: this.filter6,
                AdjustUniversityCharacteristic: this.filter7,
                PageIndex: ++this.index,
                PageSize: 20,
            };
            this.$axios
                .post(Config.adjust.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.adjustInfos.push(...data.AdjustInfos);
                    this.loading = false;
                    this.total = data.TotalCount;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击搜索
        onSearch() {
            this.filter1 = [];
            this.filter2 = [];
            this.filter3 = '';
            this.filter4 = [];
            this.filter5 = [];
            this.filter6 = [];
            this.onReload();
        },
        //点击过滤
        onFilter() {
            this.keyword = '';
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.adjustInfos = [];
            this.onLoad();
        },
        //加载当前页数据
        onLoad() {
            this.getAdjustInfos();
        },
        //点击详情
        onItemClick(adjust) {
            if (adjust.AdjustGuid) {
                this.$router.push({
                    name: "Adjust1",
                    params: {
                        proxyCode: this.$store.state.proxyCode,
                        year: adjust.AdjustYear,
                        universityCode: adjust.AdjustUniversityCode,
                        collegeCode: adjust.AdjustCollegeCode,
                        majorCode: adjust.AdjustMajorCode,
                        majorLearnway: adjust.AdjustMajorLearningWay,
                    }
                });
            } else {
                this.$router.push({
                    name: "SubmitMemberOrder",
                    params: {
                        proxyCode: this.$store.state.proxyCode
                    }
                });
            }
        },
    },
    mounted() {
        this.getRoutParams();
        this.getIsMembership();
        this.getAdjustStatistic();
    }
};
</script>

<style scoped>
.major-total {
    margin-left: 10px;
    color: gray;
    font-size: 14px;
}

.adjust-statistic {
    margin-bottom: 10px;
}

.adjust-list {
    margin-top: 10px;
}

.adjust-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>