<template>
  <div class="box" @click="onClick">
    <div class="box-top">
      <van-image v-if="unlocked" :src="img" class="box-top_img" />
      <div class="box-top_content">
        <div class="box-top_line1">
          <span v-if="unlocked" class="box-top_title">{{ universityName }}-{{ majorName }}</span>
          <span v-else class="box-top_title">
            <van-image class="lock-img" fit="contain" :src="require('@/assets/images/comm/lock.png')"></van-image>
            <span> - </span>
            <span>{{ majorName }}</span>
          </span>
          <div v-if="unlocked" class="box-top_province">
            <img src="@/assets/images/mt/position.png" class="box-top_province_img" />
            <span class="box-top_province_txt">{{ universityProvince }}</span>
          </div>
        </div>
        <div class="box-top_line2">
          <div class="box-top_item">
            <span>年份：</span>
            <span>{{ adjustYear }}</span>
          </div>
          <div class="box-top_item">
            <span>专业代码：</span>
            <span>{{ majorCode }}</span>
          </div>
          <div class="box-top_item">
            <span>位置：</span>
            <span v-if="unlocked">{{ universityPartition }}区</span>
            <van-image v-else class="lock-img" fit="contain" :src="require('@/assets/images/comm/lock.png')"></van-image>
          </div>
        </div>
      </div>
    </div>
    <div class="box-bottom">
      <div class="box-bottom_item">
        <div v-if="unlocked" class="box-bottom_title">{{ collegeName }}</div>
        <van-image v-else class="lock-img" fit="contain" :src="require('@/assets/images/comm/lock.png')"></van-image>
        <div class="box-bottom_text">所属院系</div>
      </div>
      <div class="box-bottom_item">
        <div v-if="unlocked" class="box-bottom_title">{{ majorLearnway }}</div>
        <van-image v-else class="lock-img" fit="contain" :src="require('@/assets/images/comm/lock.png')"></van-image>
        <div class="box-bottom_text">学习方式</div>
      </div>
      <div class="box-bottom_item">
        <div v-if="unlocked" class="box-bottom_title">{{ adjustPeopleNumber }}</div>
        <van-image v-else class="lock-img" fit="contain" :src="require('@/assets/images/comm/lock.png')"></van-image>
        <div class="box-bottom_text">调剂名额</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdjustItem",
  components: {

  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: null,
    },
    universityName: {
      type: String,
      default: '北京大学',
    },
    universityProvince: {
      type: String,
      default: '北京',
    },
    universityPartition: {
      type: String,
      default: 'A',
    },
    universityCharacteristic: {
      type: String,
      default: '985 211',
    },
    collegeCode: {
      type: String,
      default: '001',
    },
    collegeName: {
      type: String,
      default: '科学技术学院',
    },
    majorName: {
      type: String,
      default: '科学技术',
    },
    majorCode: {
      type: String,
      default: '100100',
    },
    majorLearnway: {
      type: String,
      default: '全日制',
    },
    adjustYear: {
      type: String,
      default: '2023',
    },
    adjustPeopleNumber: {
      type: String,
      default: '--',
    },
  },
  data() {
    return {};
  },
  computed: {
    characteristicGroup: function () {
      var arr = this.universityCharacteristic.split(' ');
      arr.splice(0, 2);
      return arr;
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.box:hover {
  color: #ff6602;
}

.box-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .box-top_img {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .box-top_content {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;

    .box-top_line1 {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .box-top_title {
        flex: 1;
        width: 0;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
      }

      .box-top_province {
        flex-shrink: 0;
        width: 50px;

        .box-top_province_txt {
          color: #888;
          font-size: 13px;
        }

        .box-top_province_img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }

    .box-top_line2 {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;

      .box-top_item {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #888;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
      }
    }
  }
}

.box-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .box-bottom_item {
    width: 33%;
    text-align: center;
  }

  .box-bottom_title {
    color: rgba(0, 0, 0, 0.64);
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0;
  }

  .box-bottom_text {
    color: rgba(0, 0, 0, 0.24);
    font-size: 12px;
  }
}

.lock-img {
  width: 12px;
  height: 14px;
}
</style>
